$ ->
    $('.big-list h3 a').on 'click', (e) ->
        $section = $(e.currentTarget).parents('section')
        if $section.find('h3').hasClass('active')
            $section.find('ul').hide()
            $section.find('h3').removeClass('active')
            $section.find('h3').find('svg').svgSet('ico-angle-down')
            return false
        $root = $('.big-list')
        $root.find('ul').hide()
        $root.find('h3').removeClass('active')
        $root.find('h3').find('svg').svgSet('ico-angle-down')

        $ul = $section.find('ul')
        $ul.show()
        $section.find('h3').addClass('active')
        $section.find('h3').find('svg').svgSet('ico-angle-up')
        return false